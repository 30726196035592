@use "mixins" as *
.homePage
  padding-right: 8px
  padding-left: 8px
  box-shadow: none !important
  min-height: var(--withoutHeaderHeight)

  :global(.loader)
    background: transparent

  > *:not(:global(.loader)):first-child
    margin-top: var(--home_itemGap)

  //for banner block
  .homeBannerContainer:first-child
    margin-top: 8px

.homeBannerContainer
  position: relative
  margin-bottom: 8px

  > div :global
    .slick-slider,
    .slick-list,
    .slick-track
      border-radius: 4px

    .slick-track
      display: flex
      justify-content: center
      .slick-slide
        margin: 0 4px
        > div
          max-width: 414px
      .banner-item
        border-radius: 4px
        width: 100%

        :global(.imageNext)
          width: 100%
          overflow: hidden
          justify-content: center
          max-height: 250px

          img
            width: 100%
            height: auto
            min-width: 100%
            min-height: 100%
            object-fit: cover
            border-radius: 4px
            margin: 0 auto

        video
          width: 100%
          border-radius: 4px
          margin: 0 auto

      // @media screen and (max-width: 932px) and (orientation: landscape)
      //   .slick-slide > div
      //     max-width: 280px
      // @media screen and (max-width: 844px) and (orientation: portrait)
      //   .slick-slide > div
      //     max-width: 320px
      // @media screen and (max-width: 440px)
      //   .slick-slide > div
      //     max-width: 100%

      @media screen and (max-width: 430px)
        .slick-slide
          margin: 0 1px
