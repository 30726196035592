@use "mixins" as *
.sportGames
    background: rgb(var(--home_tournamentItemBg))
    padding: 0 2px
    border-radius: 4px
    margin-bottom: 8px
    position: relative
    min-height: calc(42px + 164px + var(--sportsNav_height) + var(--home_itemGap))

    > :global(.loader)
        position: static
        height: calc(224px + var(--home_itemGap))

    & :global
        .slick-slider
            padding: 8px 2px var(--home_itemGap) var(--home_itemGap)
            margin: 0 -2px
            height: calc(164px + var(--home_itemGap))
            .slick-track
                display: flex
                &:not(:has(.slick-slide:nth-child(2)))
                    .slick-slide
                        width: 100%
                        > div > div > div
                            margin: 0 auto

.header
    min-height: 42px
    @include flex
    justify-content: space-between
    padding: 0 var(--home_itemGap)

    > span
        @include flex
        @include bold
        text-transform: uppercase
        font-size: 16px
        line-height: 17px
        color: #FFD801
        :global(.svg-icon)
            margin-right: 8px

    > a
        color: #fff

    + div
        margin: var(--sportGames_navMargin)

@media screen and (max-width: 376px) and (orientation: portrait)
    .sportGames :global(.slick-slide)
        width: 100%
