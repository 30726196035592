@mixin flex($important: false)
  @if $important
    display: flex !important
    align-items: center !important
  @else
    display: flex
    align-items: center

@mixin bold
  font-weight: bold

@mixin ellipsis
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

@mixin separator($color)
  content: ""
  width: 1px
  height: 22px
  background-image: linear-gradient($color 33%, rgba(255, 255, 255, 0) 0%)
  background-position: right
  background-size: 1px 2px
  background-repeat: repeat-y
  margin: 0 8px
  display: block

@mixin backArrowBtn
  content: ""
  transform: rotate(90deg)
  display: inline-block
  width: 14px
  background: var(--icon-arrow)
  background-size: 14px auto
  height: 7px
  margin-top: -1px

@mixin star($width)
  mask-image: var(--icon-favorite-star)
  mask-size: $width $width
  width: $width
  min-width: $width
  height: $width

@mixin accountStickyHeader
  position: sticky
  top: var(--headerHeight)
  z-index: 5
  background: var(--body_bg)
  @include flex
  height: 52px
  min-height: 52px
  border-bottom: 1px dotted var(--acount_headerBorder)
  margin-bottom: 10px

  > div:first-of-type
    text-transform: uppercase
    @include flex
    margin-right: auto

    > span
      @include flex

      :global(.svg-icon)
        margin-right: 8px
        svg
          width: 20px
          height: 18px
        path
          fill: var(--account_iconFill)
        :global(.account-menu-item-bg)
          fill: var(--account_iconBg)

      &:before
        content: ""
        @include separator(var(--sport_separator))

@mixin fadeIn($duration: .3s)
  animation: fadeIn $duration
  @keyframes fadeIn
    0%
      opacity: 0
    100%
      opacity: 1

@mixin pointer($important: false)
  @if $important
    cursor: pointer !important
  @else
    cursor: pointer

@mixin modalHeader
  @include flex
  min-height: 50px
  height: 50px
  border-bottom: 1px solid var(--login_borderDark)
  box-shadow: 0 1px var(--login_borderLight)
  padding: 0 20px
  font-size: 20px
  color: #fff
  @include bold
  position: relative
  &:after
    content: ""
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 1px
    background: var(--login_borderDark)
    border-bottom: 1px solid var(--login_borderLight)

// default wallet mixins //

@mixin blueButton($width, $height)
  width: $width
  height: $height
  @include bold
  background: var(--wallet_baseBtnBg)

  &:active
    background: var(--wallet_baseBtnActiveBg)

@mixin greyButton($width, $height)
  width: $width
  height: $height
  color: var(--wallet_greyBtnColor)
  background: var(--wallet_greyBtnBg)
  @include bold

  &:active
    background: var(--wallet_greyBtnActiveBg)

@mixin button()
  @include flex
  justify-content: center
  color: #fff
  border: 0
  border-radius: var(--base_borderRadius)
  font-size: 12px
  transition: opacity 0.3s

  &:hover:not(:active)
    cursor: pointer

  &:disabled
    opacity: 0.5
    pointer-events: none

@mixin blue-btn()
  @include blueButton(138px, 32px)

@mixin grey-btn()
  @include greyButton(138px, 32px)

@mixin walletPercentBtn()
  @include flex
  justify-content: center
  height: 28px
  border-radius: var(--base_borderRadius)
  color: #fff
  font-size: 13px
  flex: 1
  border: 1px solid var(--wallet_percentBtnBorder)
  background: var(--wallet_percentBtnBg)
  min-width: 50px
  margin-left: 6px
  transition: opacity 0.3s

  &.selected
    color: var(--wallet_percentBtnActiveColor)
    background: var(--wallet_percentBtnActiveBg)
    border-color: var(--wallet_percentBtnActiveBorder)
    pointer-events: none

  &:active
    background: var(--wallet_percentBtnActiveBg)
    border-color: var(--wallet_percentBtnActiveBorder)

  &:disabled
    opacity: .5
    pointer-events: none

@mixin walletAmountInput()
  width: 100%
  background: var(--wallet_fieldBg)
  font-size: 14px
  color: #fff
  padding: 0 28px 0 10px
  margin-left: auto
  border: 1px solid var(--wallet_fieldBg)
  @include bold
  border-radius: var(--base_borderRadius)
  height: 36px
  -moz-appearance: textfield

  &:read-only
    opacity: .95

  &::placeholder
    color: rgba(var(--wallet_txtColor),.8)
    font-size: 12px
    font-weight: normal

  &.hasError
    border: 1px solid #e42d5b

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  &:focus
    outline: none
